<h1 class="ap-hidden" [innerText]="title"></h1>
<nav>
    <h2 class="ap-hidden">Navigation</h2>
    <a class="ap-hidden" href="#about">Skip to content</a>
    <app-menu
        #apMenu
        [class.ap-menu_overlay]="enableOverlayNav"
        [class.ap-menu_mobile-overlay]="enableMobileOverlay"
        (clickedMenu)="onClick($event)">
    </app-menu>
</nav>
<main>
    <h2 id="ap-content" class="ap-hidden">Main Content</h2>
    <app-intro></app-intro>
    <app-about></app-about>
    <app-gallery></app-gallery>
    <app-contact></app-contact>
</main>