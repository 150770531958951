/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./menu/menu.component.ngfactory";
import * as i3 from "./menu/menu.component";
import * as i4 from "./toggle.service";
import * as i5 from "./intro/intro.component.ngfactory";
import * as i6 from "./intro/intro.component";
import * as i7 from "./about/about.component.ngfactory";
import * as i8 from "./about/about.component";
import * as i9 from "./gallery/gallery.component.ngfactory";
import * as i10 from "./gallery/gallery.component";
import * as i11 from "./contact/contact.component.ngfactory";
import * as i12 from "./contact/contact.component";
import * as i13 from "./app.component";
import * as i14 from "./font-observer.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { apMenu: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 0, "h1", [["class", "ap-hidden"]], [[8, "innerText", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "nav", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h2", [["class", "ap-hidden"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Navigation"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["class", "ap-hidden"], ["href", "#about"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Skip to content"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-menu", [], [[2, "ap-menu_overlay", null], [2, "ap-menu_mobile-overlay", null], [2, "ap-menu_overlay_focused", null]], [[null, "clickedMenu"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickedMenu" === en)) {
        var pd_0 = (_co.onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MenuComponent_0, i2.RenderType_MenuComponent)), i1.ɵdid(8, 4243456, [[1, 4], ["apMenu", 4]], 0, i3.MenuComponent, [i1.Renderer2, i1.ElementRef, i4.ToggleService], null, { clickedMenu: "clickedMenu" }), (_l()(), i1.ɵeld(9, 0, null, null, 10, "main", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h2", [["class", "ap-hidden"], ["id", "ap-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Main Content"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-intro", [], null, null, null, i5.View_IntroComponent_0, i5.RenderType_IntroComponent)), i1.ɵdid(13, 114688, null, 0, i6.IntroComponent, [], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "app-about", [], null, null, null, i7.View_AboutComponent_0, i7.RenderType_AboutComponent)), i1.ɵdid(15, 114688, null, 0, i8.AboutComponent, [], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 1, "app-gallery", [], null, null, null, i9.View_GalleryComponent_0, i9.RenderType_GalleryComponent)), i1.ɵdid(17, 49152, null, 0, i10.GalleryComponent, [i4.ToggleService], null, null), (_l()(), i1.ɵeld(18, 0, null, null, 1, "app-contact", [], null, null, null, i11.View_ContactComponent_0, i11.RenderType_ContactComponent)), i1.ɵdid(19, 49152, null, 0, i12.ContactComponent, [i4.ToggleService], null, null)], function (_ck, _v) { _ck(_v, 13, 0); _ck(_v, 15, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.enableOverlayNav; var currVal_2 = _co.enableMobileOverlay; var currVal_3 = i1.ɵnov(_v, 8).menuFocused; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, [["window", "scroll"], ["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowScroll() !== false);
        ad = (pd_0 && ad);
    } if (("window:resize" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onWindowResize() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 4308992, null, 0, i13.AppComponent, [i14.FontObserverService, i1.ElementRef, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i13.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
