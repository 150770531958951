/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./menu.component";
import * as i3 from "../toggle.service";
var styles_MenuComponent = [i0.styles];
var RenderType_MenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuComponent, data: {} });
export { RenderType_MenuComponent as RenderType_MenuComponent };
export function View_MenuComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { menuList: 1 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "ap-menu__burger"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["aria-label", "Navigation button"], ["class", "hamburger hamburger--emphatic"], ["type", "button"]], [[2, "is-active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "hamburger-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "hamburger-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "ap-text-big-title ap-menu__logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "ap-menu__item ap-menu__about"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, [[1, 0], ["menuItem", 1]], null, 1, "a", [["class", "ap-link ap-menu__item-link"], ["href", "#about"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["About Me"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "ap-menu__item ap-menu__portfolio"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, [[1, 0], ["menuItem", 1]], null, 1, "a", [["class", "ap-link ap-menu__item-link"], ["href", "#portfolio"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Portfolio"])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "ap-menu__item ap-menu__contact"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, [[1, 0], ["menuItem", 1]], null, 1, "a", [["class", "ap-link ap-menu__item-link"], ["href", "#contact"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Contact"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isActive; _ck(_v, 2, 0, currVal_0); }); }
export function View_MenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menu", [], [[2, "ap-menu_overlay_focused", null]], null, null, View_MenuComponent_0, RenderType_MenuComponent)), i1.ɵdid(1, 4243456, null, 0, i2.MenuComponent, [i1.Renderer2, i1.ElementRef, i3.ToggleService], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).menuFocused; _ck(_v, 0, 0, currVal_0); }); }
var MenuComponentNgFactory = i1.ɵccf("app-menu", i2.MenuComponent, View_MenuComponent_Host_0, {}, { clickedMenu: "clickedMenu" }, []);
export { MenuComponentNgFactory as MenuComponentNgFactory };
