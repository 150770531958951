import * as FontFaceObserver from 'fontfaceobserver';
import * as i0 from "@angular/core";
var FontObserverService = /** @class */ (function () {
    function FontObserverService() {
    }
    FontObserverService.prototype.listen = function () {
        var abrilFatface = new FontFaceObserver('Abril Fatface');
        var raleway = new FontFaceObserver('Raleway');
        var roboto = new FontFaceObserver('Roboto');
        var robotoMono = new FontFaceObserver('Roboto Mono');
        return Promise.all([
            abrilFatface.load(),
            raleway.load(),
            roboto.load(),
            robotoMono.load(),
        ]);
    };
    FontObserverService.ngInjectableDef = i0.defineInjectable({ factory: function FontObserverService_Factory() { return new FontObserverService(); }, token: FontObserverService, providedIn: "root" });
    return FontObserverService;
}());
export { FontObserverService };
