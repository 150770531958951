/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./gallery.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../visible.directive";
import * as i4 from "./gallery.component";
import * as i5 from "../toggle.service";
var styles_GalleryComponent = [i0.styles];
var RenderType_GalleryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GalleryComponent, data: {} });
export { RenderType_GalleryComponent as RenderType_GalleryComponent };
function View_GalleryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "ap-gallery__item-tags"]], [[8, "innerText", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getTags(_v.parent.context.$implicit.tags); _ck(_v, 0, 0, currVal_0); }); }
function View_GalleryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [], [[8, "innerText", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.description; _ck(_v, 0, 0, currVal_0); }); }
function View_GalleryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "ap-gallery__item"]], [[2, "ap-gallery__item_enabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleService.toggle(_v.context.$implicit, _co.galleryItems, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "ap-gallery__link"]], [[8, "href", 4], [8, "title", 0], [4, "backgroundImage", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "ap-hidden"]], [[8, "innerText", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "ap-gallery_item-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "ap-gallery__item-title"]], [[8, "innerText", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GalleryComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GalleryComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_6 = _v.context.$implicit.tags; _ck(_v, 6, 0, currVal_6); var currVal_7 = _v.context.$implicit.description; _ck(_v, 8, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.toggle; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.link; var currVal_2 = _v.context.$implicit.title; var currVal_3 = (("url(" + _v.context.$implicit.imageUrl) + ")"); _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _v.context.$implicit.title; _ck(_v, 2, 0, currVal_4); var currVal_5 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_5); }); }
export function View_GalleryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [["class", "ap-hidden"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["My Work"])), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "ap-container ap-gallery__container"], ["id", "portfolio"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [["class", "ap-gallery__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["appVisible", "ap-gallery__title_grow"], ["class", "ap-gallery__title"]], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onWindowScroll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i3.VisibleDirective, [i1.Renderer2, i1.ElementRef], { appVisible: [0, "appVisible"] }, null), (_l()(), i1.ɵted(-1, null, ["Portfolio"])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "p", [["class", "ap-gallery__item-title ap-gallery__behance-link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "a", [["class", "ap-link ap-gallery__behance-link-link"], ["href", "https://www.behance.net/alealexander"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You can see more of my work over at "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "ap-blue ap-underline"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Behance"])), (_l()(), i1.ɵted(-1, null, [". "])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "ap-gallery__grid"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GalleryComponent_1)), i1.ɵdid(15, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "ap-gallery__title_grow"; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.galleryItems; var currVal_2 = _co.trackById; _ck(_v, 15, 0, currVal_1, currVal_2); }, null); }
export function View_GalleryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-gallery", [], null, null, null, View_GalleryComponent_0, RenderType_GalleryComponent)), i1.ɵdid(1, 49152, null, 0, i4.GalleryComponent, [i5.ToggleService], null, null)], null, null); }
var GalleryComponentNgFactory = i1.ɵccf("app-gallery", i4.GalleryComponent, View_GalleryComponent_Host_0, {}, {}, []);
export { GalleryComponentNgFactory as GalleryComponentNgFactory };
