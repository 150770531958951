<div class="ap-menu__burger">
    <button aria-label="Navigation button" class="hamburger hamburger--emphatic" [class.is-active]="isActive" (click)="onClick()"
        type="button">
        <span class="hamburger-box">
            <span class="hamburger-inner"></span>
        </span>
    </button>
</div>
<div class="ap-text-big-title ap-menu__logo"></div>
<div class="ap-menu__item ap-menu__about">
    <a #menuItem class="ap-link ap-menu__item-link" (click)="toggleMenu()" href="#about">About Me</a>
</div>
<div class="ap-menu__item ap-menu__portfolio">
    <a #menuItem class="ap-link ap-menu__item-link" (click)="toggleMenu()" href="#portfolio">Portfolio</a>
</div>
<div class="ap-menu__item ap-menu__contact">
    <a #menuItem class="ap-link ap-menu__item-link" (click)="toggleMenu()" href="#contact">Contact</a>
</div>