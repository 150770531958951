import { ElementRef, AfterViewInit, Renderer2, QueryList, EventEmitter } from '@angular/core';
import { ToggleService } from '../toggle.service';
var MenuComponent = /** @class */ (function () {
    function MenuComponent(renderer, elementRef, toggleService) {
        this.renderer = renderer;
        this.elementRef = elementRef;
        this.toggleService = toggleService;
        this.clickedMenu = new EventEmitter();
        this.menuFocused = false;
        this.isActive = false;
    }
    MenuComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.menuList.forEach(function (menuItem) {
            var menuElement = menuItem.nativeElement;
            _this.renderer.listen(menuElement, 'focus', function (event) {
                _this.menuFocused = _this.menuList.some(function (element) {
                    return event.target === element.nativeElement;
                });
            });
            _this.renderer.listen(menuElement, 'blur', function (event) {
                _this.menuFocused = _this.menuList.some(function (element) {
                    return document.activeElement === element.nativeElement;
                });
            });
        });
    };
    MenuComponent.prototype.onClick = function () {
        this.isActive = !this.isActive;
        this.clickedMenu.emit(this.isActive);
    };
    MenuComponent.prototype.toggleMenu = function () {
        if (this.toggleService.getCalculatedViewPortSize() < 813) {
            this.onClick();
        }
    };
    return MenuComponent;
}());
export { MenuComponent };
