import * as i0 from "@angular/core";
var ToggleService = /** @class */ (function () {
    function ToggleService() {
    }
    ToggleService.prototype.toggle = function (clickableItem, clickableItemCollection, event) {
        clickableItem.toggle = !!!clickableItem.toggle;
        if (clickableItem.toggle && this.getCalculatedViewPortSize() < 600) {
            event.preventDefault();
        }
        clickableItemCollection.forEach(function (item) {
            item.toggle = false;
        });
        clickableItem.toggle = !!!clickableItem.toggle;
    };
    ToggleService.prototype.getCalculatedViewPortSize = function () {
        var windowComputedStyles = window.getComputedStyle(document.documentElement);
        var windowWidth = windowComputedStyles.width;
        return Number(windowWidth.replace('px', ''));
    };
    ToggleService.ngInjectableDef = i0.defineInjectable({ factory: function ToggleService_Factory() { return new ToggleService(); }, token: ToggleService, providedIn: "root" });
    return ToggleService;
}());
export { ToggleService };
