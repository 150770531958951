/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./intro.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./intro.component";
var styles_IntroComponent = [i0.styles];
var RenderType_IntroComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IntroComponent, data: {} });
export { RenderType_IntroComponent as RenderType_IntroComponent };
export function View_IntroComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "ap-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "ap-intro__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "ap-intro__logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "ap-intro__name ap-text-big-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ale Alexander"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "ap-intro__subtitle ap-text-body-subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Art Director | Graphic Designer"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "ap-line-break"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "far fa-hand-peace"]], null, null, null, null, null))], null, null); }
export function View_IntroComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-intro", [], null, null, null, View_IntroComponent_0, RenderType_IntroComponent)), i1.ɵdid(1, 114688, null, 0, i2.IntroComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IntroComponentNgFactory = i1.ɵccf("app-intro", i2.IntroComponent, View_IntroComponent_Host_0, {}, {}, []);
export { IntroComponentNgFactory as IntroComponentNgFactory };
