<h3 class="ap-hidden">My Work</h3>
<div class="ap-container ap-gallery__container" id="portfolio">
  <div class="ap-gallery__content">
    <div appVisible="ap-gallery__title_grow" class="ap-gallery__title">Portfolio</div>
    <p class="ap-gallery__item-title ap-gallery__behance-link">
      <a class="ap-link ap-gallery__behance-link-link" href="https://www.behance.net/alealexander">You can see more of my
        work over at
        <span class="ap-blue ap-underline">Behance</span>.
      </a>
    </p>
    <div class="ap-gallery__grid">
      <div class="ap-gallery__item" [class.ap-gallery__item_enabled]="item.toggle" *ngFor="let item of galleryItems; trackBy: trackById" (click)="toggleService.toggle(item, galleryItems, $event)">
        <a class="ap-gallery__link" [href]="item.link" [title]="item.title" [style.backgroundImage]="'url('+ item.imageUrl +')'">
          <span class="ap-hidden" [innerText]="item.title"></span>
        </a>
        <div class="ap-gallery_item-content">
          <span class="ap-gallery__item-title" [innerText]="item.title"></span>
          <span class="ap-gallery__item-tags" [innerText]="getTags(item.tags)" *ngIf="item.tags"></span>
          <span [innerText]="item.description" *ngIf="item.description"></span>
        </div>
      </div>
    </div>
  </div>
</div>