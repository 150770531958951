import { ElementRef, Renderer2, AfterViewInit, OnInit } from '@angular/core';
import { MenuComponent } from './menu/menu.component';
import { FontObserverService } from './font-observer.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(fontObserver, elementRef, renderer) {
        this.fontObserver = fontObserver;
        this.elementRef = elementRef;
        this.renderer = renderer;
        this.title = 'Ale Alexander | Portfolio';
        this.enableOverlayNav = false;
        this.enableMobileOverlay = false;
        this.window = window;
        this.menuHeight = 0;
    }
    AppComponent.prototype.onWindowScroll = function () {
        var windowScrollPosition = this.window.scrollY;
        var windowHeight = this.window.innerHeight;
        this.enableOverlayNav = windowScrollPosition > windowHeight;
    };
    AppComponent.prototype.onWindowResize = function () {
        this.updateCalculatedViewportHeight();
    };
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.fontObserver.listen().then(function () {
            _this.renderer.removeClass(_this.elementRef.nativeElement, 'ap-fout');
        });
        this.updateCalculatedViewportHeight();
    };
    AppComponent.prototype.ngAfterViewInit = function () {
        this.menuHeight = this.apMenu.elementRef.nativeElement.offsetHeight;
    };
    AppComponent.prototype.onClick = function (flag) {
        this.enableMobileOverlay = flag;
        this.toggleScroll();
    };
    AppComponent.prototype.updateCalculatedViewportHeight = function () {
        document.documentElement.style.setProperty("--root-height", this.window.innerHeight + "px");
    };
    AppComponent.prototype.toggleScroll = function () {
        var bodyElement = document.querySelector('body');
        if (this.enableMobileOverlay) {
            this.renderer.addClass(bodyElement, 'ap-no-scroll');
        }
        else {
            this.renderer.removeClass(bodyElement, 'ap-no-scroll');
        }
    };
    return AppComponent;
}());
export { AppComponent };
