<h3 class="ap-hidden">Contact</h3>
<div class="ap-container" id="contact">
  <div class="ap-contact__content">
    <div class="ap-contact__header">
      <p class="ap-contact__title">Have a question or</p>
      <p class="ap-contact__title ap-contact__title_large">just wanna say
        <span class="ap-contact__title ap-contact__title_italic">hello?</span>
      </p>
      <p class="ap-contact__title ap-contact__title_small">Feel free to reach out.</p>
    </div>

    <ul class="ap-contact__list">
      <li *ngFor="let item of contactItems; index as i; trackBy: trackById" class="ap-contact__item">
        <a class="ap-link ap-contact__link"
          [class.ap-contact__link_enabled]="item.toggle"
          [href]="item.link"
          [attr.aria-label]="getAriaLabel(item.ariaLabel, i)"
          (click)="toggleService.toggle(item, contactItems, $event)">
          <i [class]="item.iconClass"></i>
          <span class="ap-contact__item-text" [innerText]="item.text"></span>
        </a>
      </li>
    </ul>
  </div>
</div>