import { ElementRef, Renderer2 } from '@angular/core';
var VisibleDirective = /** @class */ (function () {
    function VisibleDirective(renderer, elementRef) {
        this.renderer = renderer;
        this.elementRef = elementRef;
    }
    VisibleDirective.prototype.onWindowScroll = function () {
        var element = this.elementRef.nativeElement;
        if (!this.isElementInViewport(element)) {
            this.renderer.removeClass(element, this.appVisible);
        }
        if (this.isElementInViewport(element)) {
            this.renderer.addClass(element, this.appVisible);
        }
    };
    VisibleDirective.prototype.isElementInViewport = function (element) {
        var clientRect = element.getBoundingClientRect();
        var elementHeight = clientRect.height;
        return clientRect.bottom > 0 &&
            clientRect.right > 0 &&
            clientRect.left < (window.innerWidth || document.documentElement.clientWidth) &&
            clientRect.top < ((window.innerHeight || document.documentElement.clientHeight)) - 4 * elementHeight;
    };
    return VisibleDirective;
}());
export { VisibleDirective };
