/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contact.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./contact.component";
import * as i4 from "../toggle.service";
var styles_ContactComponent = [i0.styles];
var RenderType_ContactComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactComponent, data: {} });
export { RenderType_ContactComponent as RenderType_ContactComponent };
function View_ContactComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [["class", "ap-contact__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "ap-link ap-contact__link"]], [[2, "ap-contact__link_enabled", null], [8, "href", 4], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleService.toggle(_v.context.$implicit, _co.contactItems, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [["class", "ap-contact__item-text"]], [[8, "innerText", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.toggle; var currVal_1 = _v.context.$implicit.link; var currVal_2 = _co.getAriaLabel(_v.context.$implicit.ariaLabel, _v.context.index); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.iconClass; _ck(_v, 2, 0, currVal_3); var currVal_4 = _v.context.$implicit.text; _ck(_v, 3, 0, currVal_4); }); }
export function View_ContactComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [["class", "ap-hidden"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Contact"])), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "ap-container"], ["id", "contact"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [["class", "ap-contact__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "ap-contact__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "ap-contact__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Have a question or"])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "p", [["class", "ap-contact__title ap-contact__title_large"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["just wanna say "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "ap-contact__title ap-contact__title_italic"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["hello?"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [["class", "ap-contact__title ap-contact__title_small"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Feel free to reach out."])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "ul", [["class", "ap-contact__list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactComponent_1)), i1.ɵdid(15, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contactItems; var currVal_1 = _co.trackById; _ck(_v, 15, 0, currVal_0, currVal_1); }, null); }
export function View_ContactComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contact", [], null, null, null, View_ContactComponent_0, RenderType_ContactComponent)), i1.ɵdid(1, 49152, null, 0, i3.ContactComponent, [i4.ToggleService], null, null)], null, null); }
var ContactComponentNgFactory = i1.ɵccf("app-contact", i3.ContactComponent, View_ContactComponent_Host_0, {}, {}, []);
export { ContactComponentNgFactory as ContactComponentNgFactory };
