import { ToggleService } from '../toggle.service';
var ContactComponent = /** @class */ (function () {
    function ContactComponent(toggleService) {
        this.toggleService = toggleService;
        this.contactItems = [
            {
                ariaLabel: "Link to Ale Alexander's resume in PDF format",
                link: '/assets/resume.pdf',
                iconClass: 'far fa-file-pdf',
                text: 'Resume'
            },
            {
                ariaLabel: "Link to Ale Alexander's Behance profile",
                link: 'https://www.behance.net/alealexander',
                iconClass: 'fab fa-behance',
                text: 'Behance'
            },
            {
                ariaLabel: "Link to Ale Alexander's Twitter profile",
                link: 'https://twitter.com/Horror_neko',
                iconClass: 'fab fa-twitter',
                text: 'Twitter'
            },
            {
                ariaLabel: "Link to Ale Alexander's Instagram account",
                link: 'https://www.instagram.com/horror_neko/',
                iconClass: 'fab fa-instagram',
                text: 'Instagram'
            },
            {
                ariaLabel: "Link to Ale Alexander's Flickr photo gallery",
                link: 'https://www.flickr.com/photos/yasu-chan/',
                iconClass: 'fab fa-flickr',
                text: 'Flickr'
            },
            {
                ariaLabel: "Do you want to email Ale Alexander? Drop me a line",
                link: 'mailto:ale@alexander.pink',
                iconClass: 'fas fa-at',
                text: 'Email'
            }
        ];
    }
    ContactComponent.prototype.getAriaLabel = function (ariaLabel, index) {
        return ariaLabel + " (" + (index + 1) + " of " + this.contactItems.length + ")";
    };
    ContactComponent.prototype.trackById = function (index, item) {
        return item.link;
    };
    return ContactComponent;
}());
export { ContactComponent };
