<div class="ap-container ap-about__container" id="about">
  <div class="ap-about__content">
    <div class="ap-about__hello">Hello!</div>
    <div class="ap-about__hello ap-about__hello_small">I'm Alejandra</div>
    <p>I graduated with a Bachelor’s in Graphic Design and Animation, I started off my career as a Creative Coordinator 9 years
      ago with the Mexican Social Security Institute where I was in charge of Internal communications. Then I cofounded and
      worked as a Graphic Designer with Dots where I was in charge of brand identity, photo retouching and web design for
      small business and management of social media for a high end mexican jewelry store, while studying my MBA.
    </p>
    <p>In the meantime I was invited to teach at the Presidency of Mexico and in my former university as a Professor of Design.</p>
    <p>
      I worked for Digital Arts Network and then Terán/TBWA as a Digital Art Director, where I created and designed digital concepts,
      strategies and focused in content art direction (animations, case studies, banners, ads, posts) and web design, for
      PepsiCo Mexico, Mead Johnson, EoS, WalMart,INFINITI and TBWA itself. Being the youngest member in my team I developed
      my skills from working directly with senior designers, senior copywriters and SM managers.
    </p>
    <p class="ap-text-mono">When life gave me lemons, I moved to New York City!</p>
  </div>
  <div class="ap-about__splash">
    <div *ngFor="let n of [1,2,3,4]" class="ap-about__plasta ap-about__plasta_{{n}}"></div>
  </div>
</div>